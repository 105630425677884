<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-21 15:24:33
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 11:34:42
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/SettleManager/component/InvoiceInfoModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="amountVisible"
    @onCancel="handleCancel"
    width="602px"
    :footer="null"
  >
    <template slot="title">
      <span>结算金额</span>
    </template>
    <a-spin :spinning="isLoading">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="publisherName" label="供应商名称" min-width="100">
        </el-table-column>
        <!-- <el-table-column prop="placeName" label="广告位名称" min-width="100">
        </el-table-column> -->
        <el-table-column prop="amount" label="结算金额" min-width="100">
          <template slot-scope="scope">
            {{ moneyText }}{{ scope.row.amount }}
          </template>
        </el-table-column>
      </el-table>
      <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
        <template #description> 暂时没有任何数据 </template>
      </m-empty>
      <a-pagination
        class="pagination"
        v-if="tableData.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { getReportDetails } from '@/apiForManage/finance/settle'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  name: 'AmountDetailModal',
  mixins: [rateAndMoneyMethod],
  data () {
    return {
      tableData: [],
      total: 0,
      isLoading: false,
      query: {
        page: 1,
        limit: 10,
        id: undefined
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.query.id = this.currentRow.id
          this.handleSearch()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    amountVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleSearch () {
      this.query.page = 1
      this.getDataList()
    },
    handleCancel () {
      this.amountVisible = false
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getReportDetails(this.query)
      const { items = [], total = 0 } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.pagination{
  padding: @smallMargin 0;
}
</style>
