/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-21 14:55:21
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 12:03:21
 * @FilePath: /mediatom-web/src/apiForManage/finance/settle.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getSettlePage: '/adxSettle/report',
  getInvoiceInfo: '/adxSettle/getInvoiceInfo',
  downloadSettleInfo: '/adxSettle/download',
  getReportDetails: '/adxSettle/reportDetails',
  updateSts: '/adxSettle/updateSts'
}

export default api

export function getSettlePage (data) {
  return request({
    url: api.getSettlePage,
    method: 'post',
    data
  })
}

export function getInvoiceInfo () {
  return request({
    url: api.getInvoiceInfo,
    method: 'get'
  })
}

export function downloadSettleInfo (data) {
  return request.post(api.downloadSettleInfo, data, {
    responseType: 'blob'
  })
}

export function getReportDetails (data) {
  return request({
    url: api.getReportDetails,
    method: 'post',
    data
  })
}

export function updateSts (data) {
  return request({
    url: api.updateSts,
    method: 'post',
    data
  })
}
